var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("About yourself")]),_c('b-row',{staticClass:"p-0 m-0"},[_c('b-col',{attrs:{"lg":"12","md":"12"}},[_c('div',{class:{
          'form-group--error': _vm.$v.form.email.$error,
          'form-group--ok': !_vm.$v.form.email.$error && _vm.$v.form.email.$dirty,
        }},[_c('label',{staticClass:"form-label"},[_vm._v("Email")]),_c('b-form-input',{staticClass:"form__input",class:{
            'mb-2': !_vm.$v.form.email.$error,
          },attrs:{"name":"email","type":"email","placeholder":"codemonkey@gmail.com"},model:{value:(_vm.$v.form.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.email.$model"}}),(_vm.$v.form.email.$dirty)?_c('div',[(!_vm.$v.form.email.required)?_c('div',{staticClass:"error mb-2"},[_vm._v(" Please input email ")]):_vm._e(),(!_vm.$v.form.email.email)?_c('div',{staticClass:"error mb-2"},[_vm._v(" Please enter a valid email ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"lg":"12","md":"12"}},[_c('div',{class:{
          'form-group--error': _vm.$v.form.nickname.$error,
          'form-group--ok':
            !_vm.$v.form.nickname.$error && _vm.$v.form.nickname.$dirty,
        }},[_c('label',{staticClass:"form-label"},[_vm._v("Username")]),_c('b-form-input',{staticClass:"form__input",class:{
            'mb-2': !_vm.$v.form.nickname.$error,
          },attrs:{"name":"name","type":"text","placeholder":"greatcoder12"},model:{value:(_vm.$v.form.nickname.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.nickname, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.nickname.$model"}}),(_vm.$v.form.nickname.$dirty)?_c('div',[(!_vm.$v.form.nickname.required)?_c('div',{staticClass:"error mb-2"},[_vm._v(" Please input a nickname ")]):_vm._e(),(!_vm.$v.form.nickname.minLength || !_vm.$v.form.nickname.maxLength)?_c('div',{staticClass:"error mb-2"},[_vm._v(" Your nickname must be 4-20 characters long ")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"text-center mt-2"},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.changeStep()}}},[_vm._v(" Next ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }