var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("About the challenge")]),_c('form',{class:{
      'form-group--error': _vm.$v.form.title.$error,
      'form-group--ok': !_vm.$v.form.title.$error && _vm.$v.form.title.$dirty,
    }},[_c('label',{staticClass:"form-label"},[_vm._v("Challenge Title")]),_c('b-form-input',{staticClass:"form__input",class:{
        'mb-2': !_vm.$v.form.title.$error,
      },attrs:{"name":"title","type":"text","placeholder":"Challenge Title"},model:{value:(_vm.$v.form.title.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.title, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.title.$model"}}),(_vm.$v.form.title.$dirty)?_c('div',[(!_vm.$v.form.title.required)?_c('div',{staticClass:"error mb-2"},[_vm._v(" Please input title ")]):_vm._e(),(!_vm.$v.form.title.minLength)?_c('div',{staticClass:"error mb-2"},[_vm._v(" Title must have at least "+_vm._s(_vm.$v.form.title.$params.minLength.min)+" letters. ")]):_vm._e()]):_vm._e()],1),_c('form',{class:{
      'form-group--error': _vm.$v.form.text.$error,
      'form-group--ok': !_vm.$v.form.text.$error && _vm.$v.form.text.$dirty,
    }},[_c('b-form-group',{staticClass:"pt-2",attrs:{"label":"Challenge Programming language","label-class":"font-weight-bold"}},[_c('b-form-select',{class:[
          'w-100 form-select',
          { 'mb-2': !_vm.$v.form.programmingLanguage.$error },
        ],attrs:{"options":_vm.languages},model:{value:(_vm.form.programmingLanguage),callback:function ($$v) {_vm.$set(_vm.form, "programmingLanguage", $$v)},expression:"form.programmingLanguage"}}),(_vm.$v.form.programmingLanguage.$dirty)?_c('div',[(!_vm.$v.form.programmingLanguage.required)?_c('div',{staticClass:"error mb-2"},[_vm._v(" Please select a programming language ")]):_vm._e()]):_vm._e()],1),_c('b-form-group',{staticClass:"pt-2",attrs:{"label":"Challenge difficulty level","label-class":"font-weight-bold"}},[_c('b-form-select',{class:['w-100 form-select', { 'mb-2': !_vm.$v.form.difficulty.$error }],attrs:{"options":_vm.difficultyLevels},model:{value:(_vm.form.difficulty),callback:function ($$v) {_vm.$set(_vm.form, "difficulty", $$v)},expression:"form.difficulty"}}),(_vm.$v.form.difficulty.$dirty)?_c('div',[(!_vm.$v.form.difficulty.required)?_c('div',{staticClass:"error mb-2"},[_vm._v(" Please select the challenge difficulty level ")]):_vm._e()]):_vm._e()],1),_c('tags-input',{staticClass:"my-2",model:{value:(_vm.form.tags),callback:function ($$v) {_vm.$set(_vm.form, "tags", $$v)},expression:"form.tags"}}),_c('label',{staticClass:"form-label"},[_vm._v("Challenge Description")]),(!_vm.previewMarkdown)?_c('div',[_c('b-form-textarea',{staticClass:"form__input",class:{
          'mb-2': !_vm.$v.form.text.$error,
        },attrs:{"label":"Challenge description","rows":"10","name":"text","placeholder":"Provide the description of your challenge here. PS. You can use markup"},model:{value:(_vm.$v.form.text.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.text, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.text.$model"}}),(_vm.$v.form.text.$dirty)?_c('div',[(!_vm.$v.form.text.required)?_c('div',{staticClass:"error mb-2"},[_vm._v(" Please input some challenge text ")]):_vm._e(),(!_vm.$v.form.text.minLength)?_c('div',{staticClass:"error mb-2"},[_vm._v(" Your challenge description must be at least 20 characters long ")]):_vm._e()]):_vm._e()],1):_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.markdownToHtml)}})])],1),_c('b-row',[_c('b-col',{attrs:{"xs":"6"}},[_c('b-button',{staticClass:"px-1 py-1 mb-1 text-capitalize",attrs:{"variant":"secondary","size":"sm"},on:{"click":function($event){_vm.previewMarkdown = !_vm.previewMarkdown}}},[_vm._v(_vm._s(_vm.previewMarkdown ? "Continue Editing" : "Preview")+" ")])],1),_c('b-col',{attrs:{"xs":"6"}},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.changeStep()}}},[_vm._v(" Next ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }